// eslint-disable-next-line import/prefer-default-export
export const USER_EVENTS = {
  create: 'user-create',
};

// Roles
export const ROLE_OWNER = 1;
export const ROLE_ADMIN = 2;
export const ROLE_AUTHOR = 3;
export const ROLE_GUEST = 4;
export const ROLE_CLIENT = 5;
export const ROLE_NAMES = {
  [ROLE_OWNER]: 'Owner',
  [ROLE_ADMIN]: 'Publisher',
  [ROLE_AUTHOR]: 'Writer',
  [ROLE_GUEST]: 'Guest writer',
  [ROLE_CLIENT]: 'Client',
};
